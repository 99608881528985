import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { SiteWrapper } from "../components/SiteWrapper";
import Seo from "../components/seo";

export default function ErrorPage() {
  const {
    allFile: { edges },
  } = useStaticQuery(graphql`
    query ErrorImage {
      allFile(filter: { name: { eq: "404" } }) {
        edges {
          node {
            name
            childImageSharp {
              gatsbyImageData(width: 800, height: 600)
            }
          }
        }
      }
    }
  `);

  const {
    node: { childImageSharp },
  } = edges[0];

  return (
    <SiteWrapper activeItem={""}>
      <Seo title={""} />

      <div className="error-page">
        <h1 className="error-page__header">404</h1>
        <h2 className="error-page__warn">
          We couldn't find what you were looking for.
        </h2>

        <Link className="error-page__link" to="/">
          See our other offerings.
        </Link>

        <GatsbyImage
          className="error-page__image"
          image={childImageSharp.gatsbyImageData}
          style={{
            width: 800,
          }}
          alt=""
        />
      </div>
    </SiteWrapper>
  );
}
